export const fieldProduct = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'variant',
    label: 'Variasi',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left space-y-3 align-top',
  },
  {
    key: 'total_inbound',
    label: 'Dikirim',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
  {
    key: 'total_inbound_confirm',
    label: 'Diterima Gudang',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
]

export const fieldSelisih = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'variant',
    label: 'Variasi',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'total_inbound',
    label: 'Dikirim',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
  {
    key: 'total_inbound_confirm',
    label: 'Diterima Gudang',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
  {
    key: 'selisih',
    label: 'Selisih',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
]
export const fieldBarangRusak = [
  {
    key: 'product_name',
    label: 'Nama Produk',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left align-top',
  },
  {
    key: 'variant',
    label: 'Variasi',
    thClass: 'bg-[#F4F4F4] text-left text-capitalize !text-black',
    tdClass: 'text-black text-left space-y-3 align-top',
  },
  {
    key: 'total_inbound',
    label: 'Dikirim',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
  {
    key: 'total_inbound_confirm',
    label: 'Diterima Gudang',
    thClass: 'bg-[#F4F4F4] text-capitalize !text-black',
    tdClass: 'text-black space-y-3 align-top',
  },
]

export const kondisiOptions = [
  { value: 0, name: 'Tidak ada barang yang rusak' },
  { value: 1, name: 'Ada barang yang rusak' },
]
